var $ = require('jquery');
global.$ = global.jQuery = $;

// Load in Bootstrap Modal JS file for global DPSG footer modals
require('bootstrap-sass/assets/javascripts/bootstrap/modal');

$(function() {

    // Footer Logo
    $(".home #footer .logo").click(function(e) {
        e.preventDefault();
        scrollTo('body');
    });

    // Navigation
    var $navContainer = $(".nav-mobile, .nav-main, .nav-overlay");

    $(".nav-mobile, .nav-overlay").click(function() {
        $navContainer.toggleClass("open");
    });

    function closeNav() {
        $navContainer.removeClass("open");
    }
    
    $(window).on("scroll resize", closeNav);
    
    $(".nav-main li a, .down").click(function(e) {
        e.preventDefault();

        var href = $(this).attr('href');
        
        if ( typeof href !== typeof undefined && href !== false ) {
            scrollTo($(this).attr('href'));
        } else {
            scrollTo($('body').find('section')[0]);
        }
    });

    // Scroll to element
    function scrollTo(element, offset, speed, callback) {

        if (offset !== 'undefined') {
            offset = 0;
        }

        if (element !== 'undefined' && element){

            var dfd = $.Deferred().done(callback);

            $('html, body').animate({
                scrollTop: $(element).offset().top - offset
            }, speed, 'swing', dfd.resolve);
        }
    }

    // Read more
    $(".history .read-more-btn").click(function() {
      var $el = $(this);
      var $readmore = $el.parent();
      var $div = $readmore.parent();
      var $ps = $div.find("p:not('.read-more')");
      var totalHeight = 0;

      // measure how tall inside should be by adding together heights of all inside paragraphs (except read-more paragraph)
      $ps.each(function() {
        totalHeight += $(this).outerHeight();
      });
      
      $div
        .css({
          // Set height to prevent instant jumpdown when max height is removed
          "height": $div.height(),
          "max-height": 9999
        })
        .animate({
          "height": totalHeight
        });
      
      // fade out read-more
      $readmore.fadeOut();
      // prevent jump-down
      return false;
    });
});
